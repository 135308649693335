export default defineNuxtRouteMiddleware((to, from) => {
    const auth = useAuth() 

    if ( auth.isAuthenticated() === false ) {

        auth.logout() 

        return navigateTo("/login")
    }
        
})
    